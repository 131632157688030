export { getFWReferrerForTracking } from './attribution/getFWReferrerForTracking'
export { decorateWithFWReferrer } from './attribution/helpers'
export { persistFWReferrer } from './attribution/persistFWReferrer'
export {
  getLastAssistanceIdWithAnswer,
  getLastAvaEngagedWithAnswerTimestamp,
  getLastBusinessMembershipIdCookie,
  getLastChannelId,
  getLastChatChannelId,
  getLastEngagedTimestamp,
  getLastVideoId,
} from './customTracking'
export { getGuestId } from './guestId'
export {
  appContextToTrackingData,
  appContextToTrackingDataPixel,
} from './senders/helpers/appContext'
export { nowAndNowLocalToString } from './senders/helpers/nowAndNowLocal'
export { getTrackingProduct, locale } from './senders/helpers/tracking.utils'
export { sendPixelTracking } from './senders/sendPixelTracking'
export { sendPixelTrackingWithSession } from './senders/sendPixelTrackingWithSession'
export type { Session } from './session'
export { getSession } from './session'
export type {
  Consent,
  ISendPixelTracking,
  PixelTrackingEndpoint,
} from './types'
