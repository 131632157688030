import { pruneNilValues } from '@core/object'
import type { Session } from '@core/tracking'

/**
 * Maps session for tracking events
 */
export const prepareSessionFields = (session: Session) => {
  return pruneNilValues({
    session_id: session.id,
    session_type: session.type,
    event_properties: {
      session_count: session.session_count,
    },
  })
}
